import React from 'react';
import {Navigate, Outlet} from "react-router";
import PoslovodjaLayout from "../layout/PoslovodjaLayout";

const PoslovodjaRoute = () => {
    const role = localStorage.getItem("role");

    if (role != "Poslovodja") {
        return <Navigate to="/unauthorized"/>;
    }

    return (
        <PoslovodjaLayout>
            <Outlet/>
        </PoslovodjaLayout>
    );
};

export default PoslovodjaRoute;