import React, {useState} from "react";
import {Alert, Box, CircularProgress, Container, Fab, Grid, Paper,} from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {fetchSuppliers, getSuppliers} from "../../../api/supplierApi";
import {tableLang} from "../../../utils/tableLang";

const columns = [
    {
        field: "id",
        headerName: "ID",
        width: 50,
        align: "center",
        headerAlign: "center",
    },
    {
        field: "code",
        headerName: "Šifra",
        minWidth: 150,
        flex: 1,
        align: "center",
        headerAlign: "center",
    },
    {
        field: "name",
        headerName: "Naziv",
        minWidth: 150,
        flex: 1,
        align: "center",
        headerAlign: "center",
    },
    {
        field: "gln",
        headerName: "GLN",
        minWidth: 150,
        flex: 1,
        align: "center",
        headerAlign: "center",
    },
    {
        field: "min_value",
        headerName: "Minimalna vrednost",
        minWidth: 150,
        flex: 1,
        align: "center",
        headerAlign: "center",
    },
    {
        field: "lead_time",
        headerName: "Lead time",
        minWidth: 150,
        flex: 1,
        align: "center",
        headerAlign: "center",
    },
];

const SupplierPage = () => {
    const queryClient = useQueryClient();

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const {data, isLoading} = useQuery({
        queryKey: ["suppliers"],
        queryFn: () => getSuppliers(),
    });

    const mutation = useMutation({
        mutationFn: () => fetchSuppliers(),
        onSuccess: (data) => {
            setError("");
            setSuccess(data.data.message);
            setLoading(false);
            queryClient.invalidateQueries(["suppliers"]);
        },
        onError: (error) => {
            setSuccess("");
            setLoading(false);
            setError(error.data.message);
        },
    });

    const fetchProductsHandle = () => {
        try {
            setLoading(true);
            mutation.mutateAsync().catch((e) => {
            });
        } catch (e) {
        }
    };

    return (
        <Container maxWidth="xl" sx={{mt: 4, mb: 4}}>
            {success === "" ? null : (
                <Alert severity="success" sx={{mb: 2, justifyContent: "center"}}>
                    {success}
                </Alert>
            )}
            {error === "" ? null : (
                <Alert severity="error" sx={{mb: 2, justifyContent: "center"}}>
                    {error}
                </Alert>
            )}
            <Box sx={{"& > :not(style)": {m: 1, position: "fixed", right: 20}}}>
                <Fab
                    onClick={() => fetchProductsHandle()}
                    color="primary"
                    aria-label="add"
                >
                    {loading ? <CircularProgress color="secondary"/> : <CachedIcon/>}
                </Fab>
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper
                        sx={{p: 5, display: "flex", flexDirection: "column", height: 600}}
                    >
                        {isLoading ? (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignContent: "center",
                                }}
                            >
                                <CircularProgress/>
                            </div>
                        ) : (
                            <DataGrid
                                slots={{toolbar: GridToolbar}}
                                rows={isLoading ? [] : data.data}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSizeOptions={[10, 25, 100]}
                                localeText={tableLang}
                                disableRowSelectionOnClick
                            />
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default SupplierPage;
