import axios from "axios";
import { BASE_PATH } from "../services/api";

const getRop = (calc_id) => {
  const response = axios.get(BASE_PATH + "/admin/get-rop/" + calc_id, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  return response;
};

const checkRop = (calc_id) => {
  const response = axios.get(BASE_PATH + "/admin/check-rop/" + calc_id, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  return response;
};

const calculateRop = (formData) => {
  const response = axios.post(BASE_PATH + "/admin/calculate-rop", formData, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  return response;
};

export { getRop, checkRop, calculateRop };
