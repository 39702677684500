import React, {useState} from 'react';
import {Alert, Box, Button, CircularProgress, TextField, Typography} from "@mui/material";
import {useParams} from "react-router";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {createOrder, getCreatedOrder, getOrder, updateHandKolicina} from "../../api/orderApi";
import Container from "@mui/material/Container";
import UpdateButtons from "../../components/UpdateButtons";
import Grid from "@mui/material/Grid";
import AbcTwoToneIcon from "@mui/icons-material/AbcTwoTone";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import OnlinePredictionTwoToneIcon from "@mui/icons-material/OnlinePredictionTwoTone";
import Paper from "@mui/material/Paper";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import {tableLang} from "../../utils/tableLang";
import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone';

const AcquisitionPage = () => {
    const {storeId, excelId} = useParams();
    let {disabled} = false;
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [value, setValue] = useState(0);
    const [productCode, setProductCode] = useState(null);

    const queryClient = useQueryClient();

    const {data, isLoading} = useQuery({
        queryKey: ["orderGet", excelId],
        queryFn: () => getOrder(excelId),
    });

    const {data: order, isLoading: loadingOrder} = useQuery({
        queryKey: ["getCreatedOrder", excelId],
        queryFn: () => getCreatedOrder(excelId)
    });

    const mutation = useMutation({
        mutationFn: (formData) => updateHandKolicina(formData),
        onSuccess: (response) => {
            queryClient.invalidateQueries(["orderGet"]);
            setSuccess(response.data.message);
            setError("");
        },
        onError: (error) => {
            setSuccess("");
            setError(error.response.data.message);
        }
    });

    const createOrderMutation = useMutation({
        mutationFn: (formData) => createOrder(formData),
        onSuccess: (response) => {
            queryClient.invalidateQueries(["getCreatedOrder"]);
            setSuccess(response.data.message);
            setError("");
        },
        onError: (error) => {
            setSuccess("");
            setError(error.response.data.message);
        }
    })

    const handleUpdate = (e, product_code) => {
        setValue(e.target.value);
        setProductCode(product_code);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);

        if (value <= 0) {
            setSuccess("");
            setError("Broj komada mora biti veći od nule!");
            return;
        }

        if (productCode == null) {
            setSuccess("");
            setError("Šifra proizvoda ne sme biti prazna!");
            return;
        }

        formData.append('value', value);
        formData.append('calc_id', excelId);
        formData.append('product_code', productCode);

        mutation.mutateAsync(formData);
    }

    const handleCreateOrder = (e) => {
        e.preventDefault();
        const formData = new FormData();

        formData.append('calc_id', excelId);
        formData.append('store_code', storeId);

        createOrderMutation.mutateAsync(formData);
    }

    const columns = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "code",
            headerName: "Šifra proizvoda",
            minWidth: 150,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "name",
            headerName: "Naziv proizvoda",
            minWidth: 250,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "needed",
            headerName: "Potrebno",
            minWidth: 150,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Typography>{parseFloat(params.row.needed).toFixed(0)}</Typography>
            ),
        },
        {
            field: "amount_on_storage",
            headerName: "Na stanju",
            minWidth: 150,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "rop",
            headerName: "Nivo naručivanja",
            minWidth: 150,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Typography>{parseFloat(params.row.rop).toFixed(0)}</Typography>
            ),
        },
        {
            field: "amount_to_get",
            headerName: "Za nabaviti",
            minWidth: 150,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Typography>{parseFloat(params.row.amount_to_get).toFixed(0)}</Typography>
            ),
        },
        {
            field: "transport_package",
            headerName: "Transportno pakovanje",
            minWidth: 200,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Typography>{parseFloat(params.row.transport_package).toFixed(0)}</Typography>
            ),
        },
        {
            field: "force_transport_package",
            headerName: "Forsiranje transportnog pakovanja",
            minWidth: 250,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                if (params.row.force_transport_package == 1) {
                    return <p style={{color: 'green'}}>DA</p>
                } else {
                    return <p style={{color: 'red'}}>NE</p>
                }
            }
        },
        {
            field: "last_supply_price",
            headerName: "Poslednja nabavna cena",
            minWidth: 250,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <p>{params.row.last_supply_price == null ? "Nema cenu!" : parseFloat(params.row.last_supply_price).toFixed(2) + " EUR"}</p>
            ),
        },
        {
            field: "amount_by_transport_package",
            headerName: "Preračun količine po TP",
            minWidth: 250,
            align: "center",
            headerAlign: "center",
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return <p>{parseFloat(params.row.amount_by_transport_package).toFixed(0)}</p>
            }
        },
        {
            field: "hand_amount",
            headerName: "Ručni unos količine",
            minWidth: 250,
            align: "center",
            headerAlign: "center",
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Box component="form"
                         onSubmit={handleSubmit}
                         noValidate
                         sx={{mt: 1}}>
                        <TextField defaultValue={params.row.hand_amount}
                                   size="small" id="hand_amount"
                                   label="Ručni unos količine"
                                   variant="filled"
                                   onChange={(e) => handleUpdate(e, params.row.code)}/>
                    </Box>

                );
            }
        },
        {
            field: "summed_price",
            headerName: "Ukupna cena po TP",
            minWidth: 250,
            align: "center",
            headerAlign: "center",
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                if (params.row.summed_price != 0) {
                    return <p>{parseFloat(params.row.summed_price).toFixed(2)} EUR</p>
                } else {
                    return <p style={{color: 'red'}}>Nema cenu!</p>
                }
            }
        },
        {
            field: "hand_summed_price",
            headerName: "Ručni unos količine",
            minWidth: 250,
            align: "center",
            headerAlign: "center",
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <p>{params.row.hand_summed_price} EUR</p>
                );
            }
        },
    ];

    if (!loadingOrder) {
        disabled = order.data.message == 1 ? true : false;
    }

    return (
        <Container maxWidth="xl" sx={{mt: 4, mb: 4}}>
            {success === "" ? null : (
                <Alert severity="success" sx={{mb: 2, justifyContent: "center"}}>
                    {success}
                </Alert>
            )}
            {error === "" ? null : (
                <Alert severity="error" sx={{mb: 2, justifyContent: "center"}}>
                    {error}
                </Alert>
            )}
            <Grid container spacing={1} sx={{mb: 2}}>
                <Grid item lg={4} sm={4} xs={12}>
                    <Button
                        variant="contained"
                        size="large"
                        href={`/administrator/prodavnica/${storeId}/informacije/${excelId}/abc-ved`}
                        fullWidth
                    >
                        <AbcTwoToneIcon sx={{mr: 1}}/>
                        ABC-VED
                    </Button>
                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                    <Button
                        variant="contained"
                        size="large"
                        href={`/administrator/prodavnica/${storeId}/informacije/${excelId}/analiza`}
                        fullWidth
                    >
                        <LeaderboardIcon sx={{mr: 1}}/>
                        Analiza
                    </Button>
                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                    <Button
                        variant="contained"
                        size="large"
                        href={`/administrator/prodavnica/${storeId}/informacije/${excelId}`}
                        fullWidth
                    >
                        <OnlinePredictionTwoToneIcon sx={{mr: 1}}/>
                        Prognoza
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{p: 5, display: "flex", flexDirection: "column"}}>
                        <UpdateButtons
                            calc_id={excelId}
                            store_code={storeId}
                            success={success}
                            setSuccess={setSuccess}
                            error={error}
                            setError={setError}
                        />
                        <div style={{height: 600, width: "100%"}}>
                            {isLoading ? (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}
                                >
                                    <CircularProgress/>
                                </div>
                            ) : (
                                <DataGrid
                                    rows={isLoading ? [] : data.data}
                                    columns={columns}
                                    slots={{toolbar: GridToolbar}}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {page: 0, pageSize: 25},
                                        },
                                    }}
                                    localeText={tableLang}
                                    pageSizeOptions={[25, 50, 100]}
                                    disableRowSelectionOnClick
                                />
                            )}
                        </div>
                        <Button
                            onClick={handleCreateOrder}
                            variant="contained"
                            fullWidth
                            color="secondary"
                            sx={{mt: 2}}
                            disabled={disabled}
                        >
                            {loadingOrder ? (
                                <CircularProgress
                                    size={32}
                                    color="primary"
                                    thickness={5}
                                    sx={{mr: 2}}
                                />
                            ) : (
                                <ListAltTwoToneIcon sx={{mr: 1}}/>
                            )}
                            <Typography sx={{fontWeight: "bold", fontSize: 15}}>
                                Napravite narudžbinu
                            </Typography>
                        </Button>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default AcquisitionPage;
