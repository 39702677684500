import React from 'react';
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import DashboardTwoToneIcon from "@mui/icons-material/DashboardTwoTone";
import ListItemText from "@mui/material/ListItemText";
import ShoppingCartTwoToneIcon from "@mui/icons-material/ShoppingCartTwoTone";
import PeopleAltTwoToneIcon from "@mui/icons-material/PeopleAltTwoTone";
import WarehouseTwoToneIcon from "@mui/icons-material/WarehouseTwoTone";
import LocalShippingTwoToneIcon from "@mui/icons-material/LocalShippingTwoTone";
import AnnouncementTwoToneIcon from "@mui/icons-material/AnnouncementTwoTone";
import {Box, Link} from "@mui/material";

const AdministratorMenu = () => {
    return (
        <React.Fragment>
            <ListItemButton to="/administrator/pocetna">
                <ListItemIcon>
                    <DashboardTwoToneIcon/>
                </ListItemIcon>
                <ListItemText primary="Početna"/>
            </ListItemButton>
            <ListItemButton to="/administrator/prodavnice">
                <ListItemIcon>
                    <ShoppingCartTwoToneIcon/>
                </ListItemIcon>
                <ListItemText primary="Prodavnice"/>
            </ListItemButton>
            <ListItemButton to="/administrator/korisnici">
                <ListItemIcon>
                    <PeopleAltTwoToneIcon/>
                </ListItemIcon>
                <ListItemText primary="Korisnici"/>
            </ListItemButton>
            <ListItemButton to="/administrator/lager">
                <ListItemIcon>
                    <WarehouseTwoToneIcon/>
                </ListItemIcon>
                <ListItemText primary="Lager"/>
            </ListItemButton>
            <ListItemButton to="/administrator/dobavljaci">
                <ListItemIcon>
                    <LocalShippingTwoToneIcon/>
                </ListItemIcon>
                <ListItemText primary="Dobavljači"/>
            </ListItemButton>
            <ListItemButton to="/administrator/obavestenja">
                <ListItemIcon>
                    <AnnouncementTwoToneIcon/>
                </ListItemIcon>
                <ListItemText primary="Obaveštenja"/>
            </ListItemButton>
            <Box sx={{textAlign: "center", mt: "75%", background: "#093170", p: 2}}>
                <Link href="https://www.semenarna.si/" target="_blank">
                    <img src={require("../assets/semenarna.png")} alt="Semenarna"/>
                </Link>
            </Box>
        </React.Fragment>
    );
};

export default AdministratorMenu;