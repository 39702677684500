import axios from "axios";
import { BASE_PATH } from "../services/api";

const getLogs = async () => {
  const response = axios.get(BASE_PATH + "/admin/get-logs", {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  return response;
};

export { getLogs };
