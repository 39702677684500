import React, {useState} from 'react';
import {useMutation, useQuery, useQueryClient} from "react-query";
import {getHistoryByStore, getHistoryDates, updateHistory} from "../../../api/historyApi";
import {Alert, Box, CircularProgress, Container, Fab, Grid, Paper} from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import {tableLang} from "../../../utils/tableLang";
import {useParams} from "react-router";

const HistoryByStorePage = () => {
    const queryClient = useQueryClient();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    let {storeCode} = useParams();

    const {data: data1, isLoading: isLoading1} = useQuery({
        queryKey: ["history", storeCode],
        queryFn: () => getHistoryByStore(storeCode),
    });

    const {data: data2, isLoading: isLoading2} = useQuery({
        queryKey: ["dates"],
        queryFn: () => getHistoryDates(),
    });

    const mutation = useMutation({
        mutationFn: () => updateHistory(),
        onSuccess: (response) => {
            setError("");
            queryClient.invalidateQueries(["history"]);
            setSuccess(response.data.message);
            setLoading(false);
        },
        onError: (err) => {
            setSuccess("");
            setLoading(false);
            setError(err.data.message);
        },
    });

    let rows = [];
    let dates = [];
    if (!isLoading1) {
        rows = data1.data.map((d) => {
            const row = {
                id: d.code,
                store_code: d.store_code,
                code: d.code,
                name: d.name,
            };

            const dateAmountArray = JSON.parse(d.history);

            dateAmountArray.forEach((item) => {
                row[`${item.date}`] = parseFloat(item.value).toFixed(2);
            });

            return row;
        });
    }

    if (!isLoading2) {
        dates = data2.data;
    }

    const columns = [
        {field: "store_code", headerName: "Šifra prodavnice", width: 150},
        {field: "code", headerName: "Šifra proizvoda", width: 150},
        ...dates.map((item) => ({
            field: `${item}`,
            headerName: `Datum ${item}`,
            width: 150,
        })),
    ];

    const fetchUpdateHistory = () => {
        try {
            setLoading(true);
            mutation.mutateAsync().catch((e) => {
            });
        } catch (e) {
        }
    };

    return (
        <Container maxWidth="xl" sx={{mt: 4, mb: 4}}>
            {success === "" ? null : (
                <Alert severity="success" sx={{mb: 2, justifyContent: "center"}}>
                    {success}
                </Alert>
            )}
            {error === "" ? null : (
                <Alert severity="error" sx={{mb: 2, justifyContent: "center"}}>
                    {error}
                </Alert>
            )}
            <Box sx={{"& > :not(style)": {m: 1, position: "fixed", right: 20}}}>
                <Fab
                    onClick={() => fetchUpdateHistory()}
                    color="primary"
                    aria-label="add"
                >
                    {loading ? <CircularProgress color="secondary"/> : <CachedIcon/>}
                </Fab>
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper
                        sx={{p: 5, display: "flex", flexDirection: "column", height: 700}}
                    >
                        {isLoading1 || isLoading2 ? (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignContent: "center",
                                }}
                            >
                                <CircularProgress/>
                            </div>
                        ) : (
                            <DataGrid
                                slots={{toolbar: GridToolbar}}
                                rows={rows}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSizeOptions={[10, 25, 100]}
                                localeText={tableLang}
                                disableRowSelectionOnClick
                            />
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default HistoryByStorePage;