import axios from "axios";
import {BASE_PATH} from "../services/api";

const getProducts = async () => {
    const response = axios.get(BASE_PATH + "/admin/get-products", {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });

    return response;
};

const fetchProducts = async () => {
    const response = axios.get(BASE_PATH + "/admin/navigator/products", {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    });

    return response;
};

const fetchProdutcsByStore = async (store_code) => {
    const response = axios.get(BASE_PATH + "/admin/navigator/products-by-store/" + store_code, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    });

    return response;
}

const getProductsByStore = async (store_code) => {
    const response = axios.get(BASE_PATH + "/admin/get-products-by-store/" + store_code, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });

    return response;
}

const updateOrderTime = async (formData) => {
    const response = axios.post(BASE_PATH + "/admin/update-order-time", formData, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
        },
    });

    return response;
}

const updateMonthlyOrders = async (formData) => {
    const response = axios.post(BASE_PATH + "/admin/update-monthly-orders", formData, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
        },
    });

    return response;
}

const updateOptimalStorage = async (formData) => {
    const response = axios.post(BASE_PATH + "/admin/update-optimal-storage", formData, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
        },
    });

    return response;
}

const updateByOption = async (formData) => {
    const response = axios.post(BASE_PATH + "/admin/update-by-option", formData, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
        },
    });

    return response;
}

const getAllSuppliers = async (store_code) => {
    const response = axios.get(BASE_PATH + "/admin/get-store-suppliers/" + store_code, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });

    return response;
}

const getAllGroups = async (store_code) => {
    const response = axios.get(BASE_PATH + "/admin/get-store-groups/" + store_code, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });

    return response;
}

export {
    getProducts,
    fetchProducts,
    fetchProdutcsByStore,
    getProductsByStore,
    updateByOption,
    getAllSuppliers,
    getAllGroups,
    updateOrderTime,
    updateMonthlyOrders,
    updateOptimalStorage
};
