import React, {useState} from "react";
import {Alert, Box, CircularProgress, Container, Fab, Grid, Paper,} from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {fetchInventory, getInventory} from "../../../api/inventoryApi";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import {tableLang} from "../../../utils/tableLang";

const columns = [
    {
        field: "id",
        headerName: "ID",
        width: 50,
        align: "center",
        headerAlign: "center",
    },
    {
        field: "store_code",
        headerName: "Šifra prodavnice",
        width: 150,
        flex: 1,
        align: "center",
        headerAlign: "center",
    },
    {
        field: "code",
        headerName: "Šifra proizvoda",
        minWidth: 150,
        flex: 1,
        align: "center",
        headerAlign: "center",
    },
    {
        field: "name",
        headerName: "Naziv proizvoda",
        minWidth: 300,
        align: "center",
        headerAlign: "center",
    },
    {
        field: "amount",
        headerName: "Količina",
        flex: 1,
        minWidth: 150,
        sortable: false,
        filterable: false,
        hideable: false,
        align: "center",
        headerAlign: "center",
    },
];

const InventoryPage = () => {
    const queryClient = useQueryClient();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const {data, isLoading} = useQuery({
        queryKey: ["inventory"],
        queryFn: () => getInventory(),
    });

    // mutacija za update lagera rucno
    const mutation = useMutation({
        mutationFn: () => fetchInventory(),
        onSuccess: (response) => {
            setError("");
            queryClient.invalidateQueries(["inventory"]);
            setSuccess(response.data.message);
            setLoading(false);
        },
        onError: (err) => {
            setSuccess("");
            setLoading(false);
            setError(err.data.message);
        },
    });

    const fetchInventoryHandle = () => {
        try {
            setLoading(true);
            mutation.mutateAsync().catch((e) => {
            });
        } catch (e) {
        }
    };

    return (
        <Container maxWidth="xl" sx={{mt: 4, mb: 4}}>
            {success === "" ? null : (
                <Alert severity="success" sx={{mb: 2, justifyContent: "center"}}>
                    {success}
                </Alert>
            )}
            {error === "" ? null : (
                <Alert severity="error" sx={{mb: 2, justifyContent: "center"}}>
                    {error}
                </Alert>
            )}
            <Box sx={{"& > :not(style)": {m: 1, position: "fixed", right: 20}}}>
                <Fab
                    onClick={() => fetchInventoryHandle()}
                    color="primary"
                    aria-label="add"
                >
                    {loading ? <CircularProgress color="secondary"/> : <CachedIcon/>}
                </Fab>
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{p: 5, display: "flex", flexDirection: "column"}}>
                        <div style={{height: 500, width: "100%"}}>
                            {isLoading ? (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}
                                >
                                    <CircularProgress/>
                                </div>
                            ) : (
                                <DataGrid
                                    slots={{toolbar: GridToolbar}}
                                    rows={isLoading ? [] : data.data}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {page: 0, pageSize: 25},
                                        },
                                    }}
                                    localeText={tableLang}
                                    pageSizeOptions={[25, 50, 100]}
                                    disableRowSelectionOnClick
                                />
                            )}
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default InventoryPage;
