import axios from "axios";
import { BASE_PATH } from "../services/api";

const getSuppliers = async () => {
  const response = axios.get(BASE_PATH + "/admin/get-suppliers", {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  return response;
};

const fetchSuppliers = async () => {
  const response = axios.get(BASE_PATH + "/admin/navigator/suppliers", {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });

  return response;
};

export {
  fetchSuppliers,
  getSuppliers
};
