import axios from "axios";
import { BASE_PATH } from "../services/api";

const getABCVED = (calc_id) => {
  const response = axios.get(BASE_PATH + "/admin/get-abc-ved/" + calc_id, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  return response;
};

const checkABCVED = (calc_id) => {
  const response = axios.get(BASE_PATH + "/admin/check-abc-ved/" + calc_id, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  return response;
};

const calculateABCVED = (formData) => {
  const response = axios.post(
    BASE_PATH + "/admin/calculate-abc-ved",
    formData,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
  return response;
};

export { getABCVED, checkABCVED, calculateABCVED };
