import React from "react";
import {Backdrop, Button, CircularProgress, Container, Grid, Paper,} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {tableLang} from "../../../utils/tableLang";
import {useQuery} from "react-query";
import {getUsers} from "../../../api/userApi";

const UsersPage = () => {
    const company_id = localStorage.getItem("company_id");
    const user_id = localStorage.getItem("user_id");

    const {data, isLoading, isError} = useQuery({
        queryKey: ["users"],
        queryFn: () => getUsers(),
    });

    const columns = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "name",
            headerName: "Ime",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "surname",
            headerName: "Prezime",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "email",
            headerName: "E-mail",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "role_id",
            headerName: "Tip Korisnika",
            minWidth: 200,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <p>
                    {params.row.role_id == 1 ? "Administrator" : "Poslovodja"}
                </p>
            ),
        },
        {
            field: "info",
            headerName: "Informacije",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="primary"
                    href={`/administrator/korisnik/${params.row.id}`}
                >
                    Informacije
                </Button>
            ),
        },
    ];
    return (
        <Container maxWidth="xl" sx={{mt: 4, mb: 4}}>
            {!isLoading ? (
                ""
            ) : (
                <Backdrop
                    sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
            )}
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{p: 5, display: "flex", flexDirection: "column"}}>
                        <Button
                            sx={{mb: 2, mt: 2}}
                            fullWidth
                            variant="contained"
                            color="primary"
                            href={`/administrator/novi-korisnik`}
                        >
                            Novi poslovodja
                        </Button>
                        <div style={{height: 400, width: "100%"}}>
                            <DataGrid
                                rows={!isLoading ? data.data : []}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {page: 0, pageSize: 5},
                                    },
                                }}
                                localeText={tableLang}
                                pageSizeOptions={[5, 10]}
                                disableRowSelectionOnClick
                            />
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default UsersPage;
