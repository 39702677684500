import axios from "axios";
import {BASE_PATH} from "../services/api";

const getHistory = async () => {
    const response = axios.get(BASE_PATH + "/admin/get-history", {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });

    return response;
};

const getHistoryByStore = async (store_code) => {
    const response = axios.get(BASE_PATH + "/admin/get-history-by-store/" + store_code, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });

    return response;
};

const getHistoryDates = async () => {
    const response = axios.get(BASE_PATH + "/admin/get-history-dates", {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });

    return response;
};

const updateHistory = async () => {
    const response = axios.get(BASE_PATH + "/admin/navigator/update-history", {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });

    return response;
}

export {getHistory, getHistoryByStore, getHistoryDates, updateHistory};
