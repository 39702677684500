import React, {useState} from 'react';
import {Alert, Autocomplete, Box, Container, Grid, Paper, TextField, Typography} from "@mui/material";
import {useMutation, useQuery} from "react-query";
import {createUser} from "../../../api/userApi";
import {getStores} from "../../../api/storesApi";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router";

const CreateUserPage = () => {
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    const [prodavnica, setProdavnica] = useState(null);
    const {data, isLoading, isError} = useQuery({
        queryKey: ["stores"],
        queryFn: () => getStores(),
    });

    const mutation = useMutation({
        mutationFn: (data) => createUser(data),
        onSuccess: (response) => {
            setError(null);
            setSuccess(response.data.message);

            setTimeout(() => {
                navigate('../../administrator/korisnici');
            }, 2000)
        },
        onError: (error) => {
            setSuccess(null);
            setError(error.response.data.message);
        },
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);

        formData.append('store_id', prodavnica.id);
        formData.append('store_code', prodavnica.code);
        if (formData.get('name') == "") {
            setError('Ime je obavezno!');
            return;
        }

        if (formData.get('surname') == "") {
            setError('Prezime je obavezno!');
            return;
        }

        if (formData.get('email') == "") {
            setError('Email je obavezan!');
            return;
        }

        if (prodavnica == null) {
            setError('Prodavnica je obavezna!');
            return;
        }

        try {
            mutation.mutateAsync(formData).catch((e) => {
            });
        } catch (error) {
            // do nothing
        }
    };

    return (
        <Container maxWidth="xl" sx={{mt: 4, mb: 4}}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{p: 5, display: "flex", flexDirection: "column"}}>
                        {success == null ? null : (
                            <Alert variant="filled" severity="success">
                                {success}
                            </Alert>
                        )}
                        {error == null ? null : (
                            <Alert variant="filled" severity="error">
                                {error}
                            </Alert>
                        )}
                        <Typography variant="h6" color="textSecondary">Novi poslovodja</Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                            <TextField name="name" required sx={{mt: 2}} fullWidth id="outlined-basic" label="Ime"
                                       variant="outlined"/>
                            <TextField name="surname" required sx={{mt: 2}} fullWidth id="outlined-basic"
                                       label="Prezime"
                                       variant="outlined"/>
                            <TextField name="email" required sx={{mt: 2}} fullWidth id="outlined-basic" label="Email"
                                       variant="outlined"/>
                            <Autocomplete
                                disablePortal
                                id="prodavnica"
                                name="prodavnica"
                                options={isLoading ? [] : data?.data}
                                loading={isLoading}
                                fullWidth
                                sx={{mt: 2}}
                                renderInput={(params) => (
                                    <TextField {...params} label="Izaberite prodavnicu"/>
                                )}
                                onChange={(e, newValue) => {
                                    if (newValue) {
                                        setProdavnica(newValue);
                                    }
                                }}
                                value={prodavnica}
                                getOptionLabel={(option) => option.name || ""}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 2, mb: 2}}
                            >
                                Dodajte novog poslovodju
                            </Button>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default CreateUserPage;