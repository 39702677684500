import React, {useState} from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import {Alert, Button, CircularProgress} from "@mui/material";
import {tableLang} from "../../utils/tableLang";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import InventoryIcon from "@mui/icons-material/Inventory";
import OnlinePredictionTwoToneIcon from "@mui/icons-material/OnlinePredictionTwoTone";
import {useParams} from "react-router";
import {useQuery} from "react-query";
import UpdateButtons from "../../components/UpdateButtons";
import {getABCVED} from "../../api/abcvedApi";

const columns = [
    {
        field: "code",
        headerName: "Šifra proizvoda",
        minWidth: 150,
        flex: 1,
        align: "center",
        headerAlign: "center",
    },
    {
        field: "name",
        headerName: "Naziv proizvoda",
        minWidth: 150,
        flex: 1,
        align: "center",
        headerAlign: "center",
    },
    {
        field: "xyz",
        headerName: "XYZ",
        minWidth: 150,
        flex: 1,
        align: "center",
        headerAlign: "center",
    },
    {
        field: "abc",
        headerName: "ABC",
        minWidth: 150,
        flex: 1,
        align: "center",
        headerAlign: "center",
    },
    {
        field: "ved",
        headerName: "VED",
        minWidth: 150,
        flex: 1,
        align: "center",
        headerAlign: "center",
    },
];

const AbcVedPage = () => {
    const {storeId, excelId} = useParams();

    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const {data, isLoading} = useQuery({
        queryKey: ["getabcved", excelId],
        queryFn: () => getABCVED(excelId),
    });

    return (
        <Container maxWidth="xl" sx={{mt: 4, mb: 4}}>
            {success === "" ? null : (
                <Alert severity="success" sx={{mb: 2, justifyContent: "center"}}>
                    {success}
                </Alert>
            )}
            {error === "" ? null : (
                <Alert severity="error" sx={{mb: 2, justifyContent: "center"}}>
                    {error}
                </Alert>
            )}

            <Grid container spacing={1} sx={{mb: 2}}>
                <Grid item lg={4} sm={4} xs={12}>
                    <Button
                        variant="contained"
                        size="large"
                        href={`/administrator/prodavnica/${storeId}/informacije/${excelId}`}
                        fullWidth
                    >
                        <OnlinePredictionTwoToneIcon sx={{mr: 1}}/>
                        Prognoza
                    </Button>
                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                    <Button
                        variant="contained"
                        size="large"
                        href={`/administrator/prodavnica/${storeId}/informacije/${excelId}/analiza`}
                        fullWidth
                    >
                        <LeaderboardIcon sx={{mr: 1}}/>
                        Analiza
                    </Button>
                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                    <Button
                        variant="contained"
                        size="large"
                        href={`/administrator/prodavnica/${storeId}/informacije/${excelId}/nabavka`}
                        fullWidth
                    >
                        <InventoryIcon sx={{mr: 1}}/>
                        Nabavka
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{p: 5, display: "flex", flexDirection: "column"}}>
                        <UpdateButtons
                            calc_id={excelId}
                            store_code={storeId}
                            success={success}
                            setSuccess={setSuccess}
                            error={error}
                            setError={setError}
                        />
                        <div style={{height: 600, width: "100%"}}>
                            {isLoading ? (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}
                                >
                                    <CircularProgress/>
                                </div>
                            ) : (
                                <DataGrid
                                    rows={isLoading ? [] : data.data}
                                    columns={columns}
                                    slots={{toolbar: GridToolbar}}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {page: 0, pageSize: 25},
                                        },
                                    }}
                                    localeText={tableLang}
                                    pageSizeOptions={[25, 50, 100]}
                                    disableRowSelectionOnClick
                                />
                            )}
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default AbcVedPage;
