import React from 'react';
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import DashboardTwoToneIcon from "@mui/icons-material/DashboardTwoTone";
import ListItemText from "@mui/material/ListItemText";
import Inventory2TwoToneIcon from "@mui/icons-material/Inventory2TwoTone";
import WarehouseTwoToneIcon from "@mui/icons-material/WarehouseTwoTone";
import {Box, Link} from "@mui/material";

const PoslovodjaMenu = () => {
    const store_id = localStorage.getItem("store_id");
    const store_code = localStorage.getItem("store_code");

    return (
        <React.Fragment>
            <ListItemButton to="/poslovodja/pocetna">
                <ListItemIcon>
                    <DashboardTwoToneIcon/>
                </ListItemIcon>
                <ListItemText primary="Početna"/>
            </ListItemButton>
            <ListItemButton to={`/poslovodja/proizvodi/${store_code}`}>
                <ListItemIcon>
                    <Inventory2TwoToneIcon/>
                </ListItemIcon>
                <ListItemText primary="Proizvodi"/>
            </ListItemButton>
            <ListItemButton to={`/poslovodja/lager/${store_code}`}>
                <ListItemIcon>
                    <WarehouseTwoToneIcon/>
                </ListItemIcon>
                <ListItemText primary="Lager"/>
            </ListItemButton>
            <Box sx={{textAlign: "center", mt: "75%", background: "#093170", p: 2}}>
                <Link href="https://www.semenarna.si/" target="_blank">
                    <img src={require("../assets/semenarna.png")} alt="Semenarna"/>
                </Link>
            </Box>
        </React.Fragment>
    );
};

export default PoslovodjaMenu;