import React from "react";
import {Alert, CircularProgress, Container, Grid, Paper} from "@mui/material";
import {useQuery} from "react-query";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import {tableLang} from "../../utils/tableLang";
import {getLogs} from "../../api/logsApi";

const columns = [
    {
        field: "id",
        headerName: "ID",
        width: 50,
        align: "center",
        headerAlign: "center",
    },
    {
        field: "message",
        headerName: "Poruka",
        minWidth: 250,
        flex: 1,
        align: "center",
        headerAlign: "center",
    },
    {
        field: "type",
        headerName: "Klasa obaveštenja",
        minWidth: 150,
        flex: 1,
        align: "center",
        headerAlign: "center",
        sortable: false,
        hideable: false,
    },
    {
        field: "fail_success",
        headerName: "Uspešnost",
        minWidth: 200,
        flex: 1,
        align: "center",
        headerAlign: "center",
        sortable: false,
        filterable: false,
        hideable: false,
        renderCell: (params) => {
            return (
                <Alert
                    variant="filled"
                    severity={params.value == 1 ? "success" : "error"}
                >
                    {params.value == 1 ? "Uspešno!" : "Greška!"}
                </Alert>
            );
        },
    },
];

const LogsPage = () => {
    const {data, isLoading, isError} = useQuery({
        queryKey: ["products"],
        queryFn: () => getLogs(),
    });

    return (
        <Container maxWidth="xl" sx={{mt: 4, mb: 4}}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{p: 5, display: "flex", flexDirection: "column"}}>
                        <div style={{height: 500, width: "100%"}}>
                            {isLoading ? (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}
                                >
                                    <CircularProgress/>
                                </div>
                            ) : (
                                <DataGrid
                                    slots={{toolbar: GridToolbar}}
                                    rows={isLoading ? [] : data.data}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {page: 0, pageSize: 25},
                                        },
                                    }}
                                    localeText={tableLang}
                                    pageSizeOptions={[25, 50, 100]}
                                    disableRowSelectionOnClick
                                />
                            )}
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default LogsPage;
