export const tableLang = {
  noRowsLabel: "Nema redova",
  noResultsOverlayLabel: "Nema rezultata.",

  // Density selector toolbar button text
  toolbarDensity: "Gustina",
  toolbarDensityLabel: "Gustina",
  toolbarDensityCompact: "Komapktno",
  toolbarDensityStandard: "Standradno",
  toolbarDensityComfortable: "Udobno",

  // Columns selector toolbar button text
  toolbarColumns: "Kolone",
  toolbarColumnsLabel: "Odaberite kolone",

  // Filters toolbar button text
  toolbarFilters: "Filteri",
  toolbarFiltersLabel: "Prikaži filtere",
  toolbarFiltersTooltipHide: "Sakrij filtere",
  toolbarFiltersTooltipShow: "Prikaži filtere",
  toolbarFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} aktivnih filtera` : `${count} aktivni filtera`,

  // Quick filter toolbar field
  toolbarQuickFilterPlaceholder: "Traži…",
  toolbarQuickFilterLabel: "traži",
  toolbarQuickFilterDeleteIconLabel: "Obrisano",

  // Export selector toolbar button text
  toolbarExport: "Izvoz",
  toolbarExportLabel: "Izvoz",
  toolbarExportCSV: "Preuzmi kao CSV",
  toolbarExportPrint: "Ispis",
  toolbarExportExcel: "Preuzmite kao Excel",

  // Columns panel text
  columnsPanelTextFieldLabel: "Pronađi kolonu",
  columnsPanelTextFieldPlaceholder: "Naslov kolone",
  columnsPanelDragIconLabel: "Promeni redosled kolone",
  columnsPanelShowAllButton: "Prikaži sve",
  columnsPanelHideAllButton: "Sakrij sve",

  // Filter panel text
  filterPanelAddFilter: "Dodajte filter",
  filterPanelRemoveAll: "Ukloniti sve",
  filterPanelDeleteIconLabel: "Izbrisati",
  filterPanelLogicOperator: "Logički operator",
  filterPanelOperator: "Operator",
  filterPanelOperatorAnd: "I",
  filterPanelOperatorOr: "Ili",
  filterPanelColumns: "Kolone",
  filterPanelInputLabel: "Vrednost",
  filterPanelInputPlaceholder: "Vrednost filtera",

  // Filter operators text
  filterOperatorContains: "sadrži",
  filterOperatorEquals: "jednak",
  filterOperatorStartsWith: "počinje sa",
  filterOperatorEndsWith: "završava se sa",
  filterOperatorIs: "je",
  filterOperatorNot: "nije",
  filterOperatorAfter: "je posle",
  filterOperatorOnOrAfter: "je na ili posle",
  filterOperatorBefore: "je pre",
  filterOperatorOnOrBefore: "je na ili pre",
  filterOperatorIsEmpty: "prazno je",
  filterOperatorIsNotEmpty: "nije prazno",
  filterOperatorIsAnyOf: "je bilo koji od",
  "filterOperator=": "=",
  "filterOperator!=": "!=",
  "filterOperator>": ">",
  "filterOperator>=": ">=",
  "filterOperator<": "<",
  "filterOperator<=": "<=",

  // Header filter operators text
  headerFilterOperatorContains: "Sadrži",
  headerFilterOperatorEquals: "Jednako",
  headerFilterOperatorStartsWith: "Počinje sa",
  headerFilterOperatorEndsWith: "Završava se sa",
  headerFilterOperatorIs: "Je",
  headerFilterOperatorNot: "Nije",
  headerFilterOperatorAfter: "Je posle",
  headerFilterOperatorOnOrAfter: "Je uključeno ili posle",
  headerFilterOperatorBefore: "Je pre",
  headerFilterOperatorOnOrBefore: "Uključeno je ili pre",
  headerFilterOperatorIsEmpty: "Prazno je",
  headerFilterOperatorIsNotEmpty: "Nije prazno",
  headerFilterOperatorIsAnyOf: "Je bilo koji od",
  "headerFilterOperator=": "Jednako",
  "headerFilterOperator!=": "Nije jednako",
  "headerFilterOperator>": "Veće od",
  "headerFilterOperator>=": "Veće ili jednako",
  "headerFilterOperator<": "Manje od",
  "headerFilterOperator<=": "Manje od ili jednako",

  // Filter values text
  filterValueAny: "bilo koji",
  filterValueTrue: "pravi",
  filterValueFalse: "lažni",

  // Column menu text
  columnMenuLabel: "Meni ",
  columnMenuShowColumns: "Prikaži kolone",
  columnMenuManageColumns: "Upravljanje kolonama",
  columnMenuFilter: "Filter",
  columnMenuHideColumn: "Sakrij kolonu",
  columnMenuUnsort: "Poništi sortiranje",
  columnMenuSortAsc: "Poređaj uzlazno",
  columnMenuSortDesc: "Poređaj silazno",

  // Column header text
  columnHeaderFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} aktivnih filtera` : `${count} aktivni filter`,
  columnHeaderFiltersLabel: "Prikaži filtere",
  columnHeaderSortIconLabel: "Vrsta",

  // Rows selected footer text
  footerRowSelected: (count) =>
    count !== 1
      ? `Odabrano je ${count.toLocaleString()} redova`
      : `${count.toLocaleString()} red odabran`,

  // Total row amount footer text
  footerTotalRows: "Ukupno redova:",

  // Total visible row amount footer text
  footerTotalVisibleRows: (visibleCount, totalCount) =>
    `${visibleCount.toLocaleString()} od ${totalCount.toLocaleString()}`,

  // Checkbox selection text
  //   checkboxSelectionHeaderName: "Odabir potvrdnog okvira",
  //   checkboxSelectionSelectAllRows: "Odaberite sve retke",
  //   checkboxSelectionUnselectAllRows: "Poništi odabir svih redaka",
  //   checkboxSelectionSelectRow: "Odaberite red",
  //   checkboxSelectionUnselectRow: "Poništi odabir retka",

  // Boolean cell text
  booleanCellTrueLabel: "Da",
  booleanCellFalseLabel: "Ne",

  // Actions cell more text
  actionsCellMore: "više",

  // Column pinning text
  pinToLeft: "Prikvači levo",
  pinToRight: "Prikvači desno",
  unpin: "Otkači",

  // Tree Data
  treeDataGroupingHeaderName: "Grupiši",
  treeDataExpand: "videti decu",
  treeDataCollapse: "sakriti decu",

  // Grouping columns
  groupingColumnHeaderName: "Grupa",
  groupColumn: (name) => `Grupiši Sprema ${name}`,
  unGroupColumn: (name) => `Zaustavi grupisanje prema ${name}`,

  // Master/detail
  detailPanelToggle: "Prebacivanje panela s detaljima",
  expandDetailPanel: "Proširiti",
  collapseDetailPanel: "Smanjiti",

  // Row reordering text
  rowReorderingHeaderName: "Promena redosleda",

  // Aggregation
  aggregationMenuItemHeader: "Agregacija",
  aggregationFunctionLabelSum: "iznos",
  aggregationFunctionLabelAvg: "prosek",
  aggregationFunctionLabelMin: "min",
  aggregationFunctionLabelMax: "max",
  aggregationFunctionLabelSize: "veličina",
};
