import axios from "axios";
import { BASE_PATH } from "../services/api";

const fetchCoefficients = async () => {
  const response = await axios.get(BASE_PATH + "/admin/get-coefficients", {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  return response;
};

const updateCoefficients = async (formData) => {
  const response = await axios.post(
    BASE_PATH + "/admin/update-coefficient",
    formData,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );

  return response;
};

export { fetchCoefficients, updateCoefficients };
