import axios from "axios";
import {BASE_PATH} from "../services/api";

const getInventory = async () => {
    const response = axios.get(BASE_PATH + "/admin/get-inventory", {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
    return response;
};

const getProductAmount = async (product_code) => {
    const response = axios.get(
        BASE_PATH + "/admin/get-product-inventory/" + product_code,
        {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        }
    );
    return response;
};

const updateInventory = async (formData) => {
    const response = axios.post(BASE_PATH + "/admin/update-inventory", formData, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });

    return response;
};

const overwriteInventory = async () => {
    const response = axios.post(BASE_PATH + "/admin/overwrite-inventory", {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
    return response;
};

const fetchInventory = async () => {
    const response = axios.get(BASE_PATH + "/admin/navigator/inventory", {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });

    return response;
};

const getInventoryByStore = async (store_code) => {
    const response = axios.get(BASE_PATH + "/admin/get-inventory-store/" + store_code, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
    return response;
}

export {
    getInventory,
    getProductAmount,
    updateInventory,
    overwriteInventory,
    fetchInventory,
    getInventoryByStore
};
