import axios from "axios";
import { BASE_PATH } from "../services/api";

const getForecast = async (calc_id) => {
  const response = axios.get(BASE_PATH + "/admin/get-forecast/" + calc_id, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  return response;
};

const getForecastDates = async (calc_id) => {
  const response = axios.get(
    BASE_PATH + "/admin/get-forecast-dates/" + calc_id,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
  return response;
};

export { getForecast, getForecastDates };
