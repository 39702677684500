import React, {useEffect, useState} from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {useParams} from "react-router";
import {useQuery} from "react-query";
import {fetchUserInfoById} from "../../api/userApi";
import {Alert, Avatar, CircularProgress, Divider, Typography} from "@mui/material";
import avatar from "../../assets/user.png";

const ManageUserStoresPage = () => {
    const {userId} = useParams();
    const [value, setValue] = useState(0);
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
    const handleMouseDownPassword1 = (event) => {
        event.preventDefault();
    };
    const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
    const handleMouseDownPassword2 = (event) => {
        event.preventDefault();
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [role, setRole] = useState("");
    const [prodavnica, setProdavnica] = useState("");

    const {data, isLoading, isError} = useQuery({
        queryKey: ["userInfo", userId],
        queryFn: () => fetchUserInfoById(userId),
    });

    useEffect(() => {
        if (!isLoading && !isError) {
            setName(data.data.name);
            setSurname(data.data.surname);
            setEmail(data.data.email);
            setProdavnica(data.data.store_name);
            if (data.data.role_id == 1) {
                setRole("Administrator");
            } else {
                setRole("Poslovodja");
            }
        }
    }, [isLoading]);
    return (
        <Container maxWidth="xl" sx={{mt: 4, mb: 4}}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{p: 5, display: "flex", flexDirection: "column"}}>
                        {isError ? (
                            <Alert severity="error">
                                Greška prilikom preuzimanja podataka o korisniku!
                            </Alert>
                        ) : (
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xs={4}
                                    sx={{
                                        justifyContent: "center",
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Avatar
                                        src={avatar}
                                        sx={{width: 128, height: 128, alignSelf: "center"}}
                                    />
                                    <Typography sx={{mt: 2, alignSelf: "center"}}>
                                        {isLoading ? <CircularProgress/> : role}
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} sx={{textAlign: "center"}}>
                                    <Typography sx={{p: 2}}>
                                        Ime: {isLoading ? <CircularProgress/> : name}
                                    </Typography>
                                    <Divider/>
                                    <Typography sx={{p: 2}}>
                                        Prezime: {isLoading ? <CircularProgress/> : surname}
                                    </Typography>
                                    <Divider/>
                                    <Typography sx={{p: 2}}>
                                        E-mail adresa: {isLoading ? <CircularProgress/> : email}
                                    </Typography>
                                    <Divider/>
                                    <Typography sx={{p: 2}}>
                                        Prodavnica: {isLoading ? <CircularProgress/> : prodavnica}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default ManageUserStoresPage;
