import React from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { Box } from '@mui/material';

const Spinner = () => {
    return (
        <Box sx={{ display: "flex", justifyContent: "center", minHeight: '100vh'}}>
            <ThreeDots
            visible={true}
            height="80"
            width="80"
            color="#2b4046"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{alignSelf: 'center'}}
            wrapperClass=""
            />
        </Box>
    );
}

export default Spinner;