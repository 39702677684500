import React, {useState} from 'react';
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    Container,
    Divider,
    FormControl,
    Grid,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Modal,
    Paper,
    Select,
    TextField,
    Typography
} from "@mui/material";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import {tableLang} from "../../../utils/tableLang";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {
    fetchProdutcsByStore,
    getAllGroups,
    getAllSuppliers,
    getProductsByStore,
    updateByOption,
    updateMonthlyOrders,
    updateOptimalStorage,
    updateOrderTime
} from "../../../api/productsApi";
import {useParams} from "react-router-dom";
import CachedIcon from "@mui/icons-material/Cached";

const ProductsByStorePage = () => {
    const {storeCode} = useParams();
    const queryClient = useQueryClient();

    const [loading, setLoading] = useState(false);

    const [orderTime, setOrderTime] = useState(null);
    const [monthlyOrders, setMonthlyOrders] = useState(null);
    const [optimalStorage, setOptimalStorage] = useState(null);

    const [mutationMessage, setMutationMessage] = useState("");
    const [mutationError, setMutationError] = useState("");

    const [option, setOption] = useState(1);
    const [optionError, setOptionError] = useState("");

    const [filter, setFilter] = useState("");
    const [filterValue, setFilterValue] = useState("");
    const [editValue, setEditValue] = useState("");

    const [openEditModal, setOpenEditModal] = useState(false);

    const products = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 100,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "code",
            headerName: "Šifra",
            width: 150,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "name",
            headerName: "Naziv proizvoda",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "ean",
            headerName: "EAN",
            minWidth: 200,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "group",
            headerName: "Grupa",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                <Typography>{params.row.group == null ? "Nema" : params.row.group}</Typography>
            }
        },
        {
            field: "supplier_name",
            headerName: "Naziv dobavljača",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                <Typography>{params.row.supplier_name == null ? "Nema" : params.row.supplier_name}</Typography>
            }
        },
        {
            field: "order_time",
            headerName: "Vreme za naručivanje",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <TextField
                        size="small"
                        fullWidth
                        id="filled-basic"
                        label="Vreme za naručivanje"
                        variant="filled"
                        defaultValue={params.row.order_time}
                        onChange={(e) => setOrderTime(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                submitOrderTime(e, params.row.code);
                            }
                        }}
                    />
                );
            },
        },
        {
            field: "monthly_orders",
            headerName: "Mesečno naručivanje",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <TextField
                        size="small"
                        fullWidth
                        id="filled-basic"
                        label="Mesečno naručivanje"
                        variant="filled"
                        defaultValue={params.row.monthly_orders}
                        onChange={(e) => setMonthlyOrders(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                submitMonthlyOrders(e, params.row.code);
                            }
                        }}
                    />
                );
            },
        },
        {
            field: "optimal_storage",
            headerName: "Optimalna zaliha",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <TextField
                        size="small"
                        fullWidth
                        id="filled-basic"
                        label="Optimalna zaliha"
                        variant="filled"
                        defaultValue={params.row.optimal_storage}
                        onChange={(e) => setOptimalStorage(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                submitOptimalStorage(e, params.row.code);
                            }
                        }}
                    />
                );
            },
        },
    ];

    const {data: productsByStore, isLoading: isLoadingProducts} = useQuery({
        queryKey: ['productsByStore', storeCode],
        queryFn: () => getProductsByStore(storeCode),
    });

    const {data: suppliers, isLoading: isLoadingSuppliers} = useQuery({
        queryKey: ['getStoreSuppliers', storeCode],
        queryFn: () => getAllSuppliers(storeCode)
    });

    const {data: groups, isLoading: isLoadingGroups} = useQuery({
        queryKey: ['getStoreGroups', storeCode],
        queryFn: () => getAllGroups(storeCode)
    });

    const mutation = useMutation({
        mutationFn: (store_code) => fetchProdutcsByStore(store_code),
        onSuccess: (resp) => {
            setMutationError("");
            queryClient.invalidateQueries(["productsByStore"]);
            setMutationMessage("Uspešno ste učitali proizvode!");
            setLoading(false);
        },
        onError: (err) => {
            setMutationMessage("");
            setLoading(false);
            setMutationError(err.data.message);
        },
    });

    const editMutation = useMutation({
        mutationFn: (formData) => updateByOption(formData),
        onSuccess: (response) => {
            queryClient.invalidateQueries(["productsByStore", storeCode]);
            setMutationMessage(response.data.message);
            setMutationError("");
        },
        onError: (error) => {
            setMutationMessage("");
            setMutationError(error.data.message);
        },
    });

    const orderTimeMutation = useMutation({
        mutationFn: (formData) => updateOrderTime(formData),
        onSuccess: (response) => {
            queryClient.invalidateQueries(["productsByStore", storeCode]);
            setMutationMessage(response.data.message);
            setMutationError("");
        },
        onError: (error) => {
            setMutationMessage("");
            setMutationError(error.data.message);
        },
    });

    const monthlyOrderMutation = useMutation({
        mutationFn: (formData) => updateMonthlyOrders(formData),
        onSuccess: (response) => {
            queryClient.invalidateQueries(["productsByStore", storeCode]);
            setMutationMessage(response.data.message);
            setMutationError("");
        },
        onError: (error) => {
            setMutationMessage("");
            setMutationError(error.data.message);
        },
    });

    const optimalStorageMutation = useMutation({
        mutationFn: (formData) => updateOptimalStorage(formData),
        onSuccess: (response) => {
            queryClient.invalidateQueries(["productsByStore", storeCode]);
            setMutationMessage(response.data.message);
            setMutationError("");
        },
        onError: (error) => {
            setMutationMessage("");
            setMutationError(error.data.message);
        },
    });

    const submitEditData = async (e) => {
        e.preventDefault();

        if (filter == null) {
            setOptionError("Molimo vas izaberite sve vrste!");
            return;
        }
        if (filterValue == null) {
            setOptionError("Molimo vas pretražite grupu!");
            return;
        }

        if (editValue == null) {
            setOptionError("Sva polja su obavezna!");
            return;
        }

        const formData = new FormData();

        formData.append("option", option);
        formData.append("code", storeCode);
        formData.append("value", editValue);
        formData.append("type", filter);
        formData.append("typeValue", filterValue);

        editMutation.mutateAsync(formData).catch((e) => {
        });
    }

    const submitOrderTime = async (e, product_code) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("store_code", storeCode);
        formData.append("product_code", product_code);
        formData.append("value", e.target.value);

        orderTimeMutation.mutateAsync(formData).catch((e) => {
        });
    }
    const submitMonthlyOrders = async (e, product_code) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("store_code", storeCode);
        formData.append("product_code", product_code);
        formData.append("value", e.target.value);

        monthlyOrderMutation.mutateAsync(formData).catch((e) => {
        });
    }
    const submitOptimalStorage = async (e, product_code) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("store_code", storeCode);
        formData.append("product_code", product_code);
        formData.append("value", e.target.value);

        optimalStorageMutation.mutateAsync(formData).catch((e) => {
        });
    }

    const handleOpenEditModal = () => setOpenEditModal(true);
    const handleCloseEditModal = () => {
        setOpenEditModal(false);
    };

    const handleFetchProductsByStore = () => {
        try {
            setLoading(true);
            mutation.mutateAsync(storeCode).catch((e) => {
            });
        } catch (e) {
        }
    };

    return (
        <Container maxWidth="xl" sx={{mt: 4, mb: 4}}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{p: 5, display: "flex", flexDirection: "column"}}>
                        {mutationError === "" ? null : (
                            <Alert sx={{mb: 2, justifyContent: "center"}} severity="error">{mutationError}</Alert>
                        )}
                        {mutationMessage === "" ? null : (
                            <Alert sx={{mb: 2, justifyContent: "center"}} severity="success">{mutationMessage}</Alert>
                        )}
                        <Grid container spacing={1} sx={{mb: 2}}>
                            <Grid item lg={6} sm={6} xs={12}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    onClick={handleFetchProductsByStore}
                                    fullWidth
                                >
                                    {loading ? <CircularProgress size={24} color="inherit"/> : (
                                        <CachedIcon sx={{mr: 1}}/>
                                    )}
                                    {loading ? null : (
                                        "Ažuriranje proizvoda"
                                    )}
                                </Button>
                            </Grid>
                            <Grid item lg={6} sm={6} xs={12}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    onClick={handleOpenEditModal}
                                    fullWidth
                                >
                                    <EditTwoToneIcon sx={{mr: 1}}/>
                                    Izmena
                                </Button>
                            </Grid>
                        </Grid>
                        <div style={{height: 600, width: "100%"}}>
                            {isLoadingProducts ? (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}
                                >
                                    <CircularProgress/>
                                </div>
                            ) : (
                                <DataGrid
                                    rows={isLoadingProducts ? [] : productsByStore.data}
                                    slots={{toolbar: GridToolbar}}
                                    columns={products}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {page: 0, pageSize: 25},
                                        },
                                    }}
                                    localeText={tableLang}
                                    pageSizeOptions={[25, 50, 100]}
                                    disableRowSelectionOnClick={true}
                                />
                            )}
                        </div>
                    </Paper>
                </Grid>
            </Grid>
            <Modal
                open={openEditModal}
                onClose={handleCloseEditModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        borderRadius: 8,
                        p: 4,
                    }}
                >
                    {mutationError === "" ? null : (
                        <Alert severity="error">{mutationError}</Alert>
                    )}
                    {mutationMessage === "" ? null : (
                        <Alert severity="success">{mutationMessage}</Alert>
                    )}
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{textAlign: "center", marginBottom: 4, mt: 2}}
                    >
                        <EditTwoToneIcon/> Izmene podataka
                    </Typography>
                    <Box
                        component="form"
                        sx={{
                            "& .MuiTextField-root": {m: 1, width: "35ch"},
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        {optionError === "" ? null : (
                            <Alert severity="error" sx={{justifyContent: "center"}}>
                                {optionError}
                            </Alert>
                        )}
                        <Divider sx={{mb: 2, mt: 2}}/>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-label">Izaberite filter</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={filter}
                                label="Izaberite"
                                onChange={(e) => {
                                    setFilter(e.target.value);
                                }}
                            >
                                <MenuItem value={1}>Grupa proizvoda</MenuItem>
                                <MenuItem value={2}>Naziv dobavljača</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{mt: 3}}>
                            {filter === 1 ?
                                (
                                    <Autocomplete
                                        options={isLoadingGroups ? [] : groups.data}
                                        loading={isLoadingGroups}
                                        getOptionLabel={(option) => option.group || ""}
                                        renderInput={(params) => <TextField {...params} variant="standard"
                                                                            label="Izaberite grupu"/>}
                                        onChange={(event, value) => {
                                            setFilterValue(value.group);
                                        }}
                                    />
                                ) :
                                (
                                    <Autocomplete
                                        options={isLoadingSuppliers ? [] : suppliers.data}
                                        loading={isLoadingSuppliers}
                                        getOptionLabel={(option) => option.supplier_name || ""}
                                        renderInput={(params) => <TextField {...params} variant="standard"
                                                                            label="Izaberite dobavljača"/>}
                                        onChange={(event, value) => {
                                            setFilterValue(value.supplier_name);
                                        }
                                        }
                                    />
                                )
                            }
                        </FormControl>
                        <FormControl variant="standard" fullWidth sx={{mt: 3}}>
                            <InputLabel id="demo-simple-select-label">Izaberite</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={option}
                                label="Izaberite"
                                onChange={(e) => {
                                    setOption(e.target.value);
                                }}
                            >
                                <MenuItem value={1}>Vreme za naručivanje</MenuItem>
                                <MenuItem value={2}>Mesečno naručivanje</MenuItem>
                                <MenuItem value={3}>Optimalna zaliha</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" fullWidth sx={{mt: 3}}>
                            <InputLabel htmlFor="input-with-icon-adornment">
                                Vrednost
                            </InputLabel>
                            <Input
                                id="input-with-icon-adornment"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <EditTwoToneIcon/>
                                    </InputAdornment>
                                }
                                onChange={(e) => {
                                    setEditValue(e.target.value);
                                }}
                            />
                        </FormControl>
                        <Button
                            type="submit"
                            sx={{width: "100%", marginTop: 4}}
                            variant="contained"
                            size="large"
                            onClick={(e) => {
                                submitEditData(e);
                            }}
                        >
                            <EditTwoToneIcon/> Izmeni
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Container>
    );
};

export default ProductsByStorePage;