import axios from "axios";
import {BASE_PATH} from "../services/api";

const fetchUserInfo = async () => {
    const response = axios.get(
        BASE_PATH + "/admin/get-user-info/" + localStorage.getItem("user_id"),
        {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        }
    );

    return response;
};

const fetchUserInfoById = async (id) => {
    const response = axios.get(
        BASE_PATH + "/admin/get-poslovodja-info/" + id,
        {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        }
    );

    return response;
};

const changePassword = async (formData) => {
    const response = axios.post(BASE_PATH + "/admin/change-password", formData, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });

    return response;
};

const getUsers = async () => {
    const response = axios.get(
        BASE_PATH + "/admin/get-users/" + localStorage.getItem("user_id"),
        {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        }
    );

    return response;
};

const createUser = async (formData) => {
    const response = axios.post(BASE_PATH + "/admin/create-user", formData, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
    return response;
};

const deleteUser = async (formData) => {
    const response = axios.post(BASE_PATH + "/admin/delete-user", formData, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
    return response;
};

export {fetchUserInfo, fetchUserInfoById, changePassword, getUsers, createUser, deleteUser};
