import * as React from "react";
import {useState} from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";
import {Alert, Icon} from "@mui/material";
import kaproscmLogo from "../../assets/kaproscmLogo.svg";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import Copyright from "../../components/Copyright";
import Spinner from "../../components/Spinner";
import {loginApi} from "../../api/authApi";
import {useMutation} from "react-query";
import {startLogoutTimer} from "../../utils/AutoLogout";

const defaultTheme = createTheme();

const LoginPage = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const [spinner, setSpinner] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const mutation = useMutation({
        mutationFn: (data) => loginApi(data),
        onSuccess: (response) => {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("user_id", response.data.user.id);
            localStorage.setItem('store_id', response.data.user.store_id);
            localStorage.setItem('store_code', response.data.user.store_code);
            localStorage.setItem(
                "role",
                response.data.user.role_id == 1 ? "Administrator" : "Poslovodja"
            );
            startLogoutTimer();

            if (response.data.user.role_id == 1) {
                navigate('/administrator/pocetna');
            } else {

                navigate('/poslovodja/pocetna');
            }
        },
        onError: (error) => {
            setError(error.response.data.message);
        },
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        try {
            mutation.mutateAsync(formData).catch((e) => {
            });
        } catch (error) {
            // do nothing
        }
    };

    React.useEffect(() => {
        setTimeout(() => setSpinner(false), 4000);
    }, []);

    if (spinner) {
        return <Spinner/>;
    }

    if (mutation.isPending) {
        return <Spinner/>;
    }

    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline/>
            <Grid
                container
                component="main"
                sx={{
                    backgroundImage: "url(https://kapromes.com/bg.jpg)",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    height: "100vh",
                }}
            >
                <Container maxWidth="xs">
                    <Box
                        sx={{
                            marginTop: 15,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            backgroundColor: "#ffffff",
                            borderRadius: 2,
                            p: 2,
                        }}
                    >
                        <Avatar sx={{m: 1, bgcolor: "#e8f0fe"}}>
                            <Icon>
                                <img src={kaproscmLogo} height={25} width={25} alt={"logo"}/>
                            </Icon>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Prijava
                        </Typography>
                        {mutation.isError ? (
                            <Alert
                                sx={{width: "100%", justifyContent: "center"}}
                                severity="error"
                            >
                                {error}
                            </Alert>
                        ) : null}
                        <Box
                            component="form"
                            onSubmit={handleSubmit}
                            noValidate
                            sx={{mt: 1}}
                        >
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="credentials"
                                name="credentials"
                                label="📧 E-mail adresa"
                                type="email"
                                autoComplete="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <FormControl sx={{width: "100%"}} variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">
                                    🔏 Šifra
                                </InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showPassword ? "text" : "password"}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    required={true}
                                    label="🔏 Šifra"
                                    name="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </FormControl>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                            >
                                Prijavite se
                            </Button>
                        </Box>
                    </Box>

                    <Copyright
                        sx={{
                            mt: 8,
                            mb: 4,
                            backgroundColor: "#ffffff",
                            p: 2,
                            borderRadius: 2,
                        }}
                    />
                </Container>
            </Grid>
        </ThemeProvider>
    );
};

export default LoginPage;
