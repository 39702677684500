import React, {useState} from "react";
import {Alert, Button, CircularProgress, Container, Grid, Paper,} from "@mui/material";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import {tableLang} from "../../../utils/tableLang";
import InfoIcon from "@mui/icons-material/Info";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {fetchStores, getStores} from "../../../api/storesApi";
import CachedIcon from "@mui/icons-material/Cached";
import {fetchProducts} from "../../../api/productsApi";

const columns = [
    {
        field: "id",
        headerName: "ID",
        width: 50,
        align: "center",
        headerAlign: "center",
    },
    {
        field: "code",
        headerName: "Šifra prodavnice",
        minWidth: 150,
        flex: 1,
        align: "center",
        headerAlign: "center",
    },
    {
        field: "name",
        headerName: "Naziv prodavnice",
        minWidth: 150,
        flex: 1,
        align: "center",
        headerAlign: "center",
    },
    {
        field: "info",
        headerName: "Informacije",
        flex: 1,
        minWidth: 150,
        sortable: false,
        filterable: false,
        hideable: false,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => (
            <Button
                variant="contained"
                color="primary"
                href={`/administrator/prodavnica/${params.row.id}/${params.row.code}`}
            >
                <InfoIcon sx={{marginRight: 1}}/> Informacije
            </Button>
        ),
    },
];

export const StoresPage = () => {
    const queryClient = useQueryClient();
    const [loading, setLoading] = useState(false);
    const [loadingAllStores, setLoadingAllStores] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const {data, isLoading} = useQuery({
        queryKey: ["stores"],
        queryFn: () => getStores(),
    });

    const mutation = useMutation({
        mutationFn: () => fetchStores(),
        onSuccess: (resp) => {
            setError("");
            queryClient.invalidateQueries(["stores"]);
            setSuccess(resp.data.message);
            setLoading(false);
        },
        onError: (err) => {
            setSuccess("");
            setLoading(false);
            setError(err.response.data.message);
        },
    });

    const mutationAllProducts = useMutation({
        mutationFn: () => fetchProducts(),
        onSuccess: (resp) => {
            setError("");
            setSuccess(resp.data.message);
            setLoadingAllStores(false);
        },
        onError: (err) => {
            setSuccess("");
            setLoadingAllStores(false);
            setError(err.response.data.message);
        },
    });

    const fetchStoresHandle = () => {
        try {
            setLoading(true);
            mutation.mutateAsync().catch((e) => {
            });
        } catch (e) {
        }
    };

    const fetchAllProductsHandle = () => {
        try {
            setLoadingAllStores(true);
            mutationAllProducts.mutateAsync().catch((e) => {
            });
        } catch (e) {
        }
    }

    return (
        <Container maxWidth="xl" sx={{mt: 4, mb: 4}}>
            {success === "" ? null : (
                <Alert severity="success" sx={{mb: 2, justifyContent: "center"}}>
                    {success}
                </Alert>
            )}
            {error === "" ? null : (
                <Alert severity="error" sx={{mb: 2, justifyContent: "center"}}>
                    {error}
                </Alert>
            )}
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{p: 5, display: "flex", flexDirection: "column"}}>
                        <Grid container spacing={1} sx={{mb: 2}}>
                            <Grid item lg={6} sm={6} xs={12}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    onClick={fetchStoresHandle}
                                    fullWidth
                                >
                                    {loading ? <CircularProgress size={24} color="inherit"/> : (
                                        <CachedIcon sx={{mr: 1}}/>
                                    )}
                                    {loading ? null : (
                                        "Ažuriranje prodavnica"
                                    )}
                                </Button>
                            </Grid>
                            <Grid item lg={6} sm={6} xs={12}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    onClick={fetchAllProductsHandle}
                                    fullWidth
                                >
                                    {loadingAllStores ? <CircularProgress size={24} color="inherit"/> : (
                                        <CachedIcon sx={{mr: 1}}/>
                                    )}
                                    {loadingAllStores ? null : (
                                        "Ažuriranje svih proizvoda"
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                        <div style={{height: 500, width: "100%"}}>
                            {isLoading ? (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}
                                >
                                    <CircularProgress/>
                                </div>
                            ) : (
                                <DataGrid
                                    slots={{toolbar: GridToolbar}}
                                    rows={isLoading ? [] : data.data}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {page: 0, pageSize: 25},
                                        },
                                    }}
                                    localeText={tableLang}
                                    pageSizeOptions={[25, 50, 100]}
                                    disableRowSelectionOnClick
                                />
                            )}
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};
