import React, {useEffect} from "react";
import {
    Alert,
    Avatar,
    Box,
    Chip,
    CircularProgress,
    Container,
    Divider,
    Grid,
    Paper,
    Tab,
    Tabs,
    TextField,
} from "@mui/material";
import CustomTabPanel from "../../../components/CustomTabPanel";
import PropTypes from "prop-types";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {fetchCoefficients, updateCoefficients} from "../../../api/setttingsApi";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const SettingsPage = () => {
    const queryClient = useQueryClient(); // needed for mutation usage

    const [value, setValue] = React.useState(0);
    const [v, setV] = React.useState("");
    const [ax, setAx] = React.useState("");
    const [bx, setBx] = React.useState("");
    const [cx, setCx] = React.useState("");
    const [ay, setAy] = React.useState("");
    const [by, setBy] = React.useState("");
    const [cy, setCy] = React.useState("");
    const [az, setAz] = React.useState("");
    const [bz, setBz] = React.useState("");
    const [cz, setCz] = React.useState("");

    const [trendA, setTrendA] = React.useState("");
    const [trendB, setTrendB] = React.useState("");
    const [trendC, setTrendC] = React.useState("");

    const [message, setMessage] = React.useState("");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const {data, isLoading, isError} = useQuery({
        queryKey: ["coefficients"],
        queryFn: () => fetchCoefficients(),
    });

    const mutation = useMutation({
        mutationFn: (formData) => updateCoefficients(formData),
        onSuccess: (success) => {
            setMessage(success.data.message);
            queryClient.invalidateQueries({queryKey: ["coefficients"]});
        },
    });

    const handleCoefChange = (name, value) => {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("value", value);
        try {
            mutation.mutateAsync(formData).catch((e) => {
            });
        } catch (error) {
            // do nothing
        }
    };

    useEffect(() => {
        if (!isLoading) {
            setV(data.data[0].value);
            setAx(data.data[1].value);
            setBx(data.data[2].value);
            setCx(data.data[3].value);
            setAy(data.data[4].value);
            setBy(data.data[5].value);
            setCy(data.data[6].value);
            setAz(data.data[7].value);
            setBz(data.data[8].value);
            setCz(data.data[9].value);
            setTrendA(data.data[10].value);
            setTrendB(data.data[11].value);
            setTrendC(data.data[12].value);
        }
    }, [isLoading]);

    return (
        <Container maxWidth="xl" sx={{mt: 4, mb: 4}}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{p: 5, display: "flex", flexDirection: "column"}}>
                        {mutation.isSuccess ? (
                            <Alert sx={{justifyContent: "center"}} severity="success">
                                {message}
                            </Alert>
                        ) : null}
                        <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="basic tabs example"
                            >
                                <Tab label="Koeficijenti" {...a11yProps(0)} />
                                <Tab label="Trendovi" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            {isLoading ? (
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <CircularProgress/>
                                </div>
                            ) : (
                                <Grid
                                    sx={{height: 400, overflow: "hidden", overflowY: "scroll"}}
                                >
                                    <Box sx={{flexGrow: 1}}>
                                        <Grid
                                            container
                                            spacing={2}
                                            sx={{
                                                textAlign: "center",
                                                justifyContent: "center",
                                                alignContent: "center",
                                            }}
                                        >
                                            <Grid item xs={4}>
                                                <Chip
                                                    sx={{p: 3}}
                                                    color="primary"
                                                    avatar={<Avatar>V</Avatar>}
                                                    label="Vrednost parametra V"
                                                />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    size="small"
                                                    fullWidth
                                                    id="filled-basic"
                                                    label="Parametar V"
                                                    variant="filled"
                                                    value={v}
                                                    onChange={(e) => setV(e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            handleCoefChange("v", v);
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Divider sx={{mt: 2}}>
                                        <Chip label="X - grupa" size="small"/>
                                    </Divider>
                                    <Box sx={{flexGrow: 1, mt: 2}}>
                                        <Grid
                                            container
                                            spacing={2}
                                            sx={{
                                                textAlign: "center",
                                                justifyContent: "center",
                                                alignContent: "center",
                                            }}
                                        >
                                            <Grid item xs={4}>
                                                <Chip
                                                    sx={{p: 3}}
                                                    color="primary"
                                                    avatar={<Avatar>AX</Avatar>}
                                                    label="Vrednost parametra AX"
                                                />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    size="small"
                                                    fullWidth
                                                    id="filled-basic"
                                                    label="Parametar AX"
                                                    variant="filled"
                                                    value={ax}
                                                    onChange={(e) => setAx(e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            handleCoefChange("ax", ax);
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={{flexGrow: 1, mt: 2}}>
                                        <Grid
                                            container
                                            spacing={2}
                                            sx={{
                                                textAlign: "center",
                                                justifyContent: "center",
                                                alignContent: "center",
                                            }}
                                        >
                                            <Grid item xs={4}>
                                                <Chip
                                                    sx={{p: 3}}
                                                    color="primary"
                                                    avatar={<Avatar>BX</Avatar>}
                                                    label="Vrednost parametra BX"
                                                />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    size="small"
                                                    fullWidth
                                                    id="filled-basic"
                                                    label="Parametar BX"
                                                    variant="filled"
                                                    value={bx}
                                                    onChange={(e) => setBx(e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            handleCoefChange("bx", bx);
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={{flexGrow: 1, mt: 2}}>
                                        <Grid
                                            container
                                            spacing={2}
                                            sx={{
                                                textAlign: "center",
                                                justifyContent: "center",
                                                alignContent: "center",
                                            }}
                                        >
                                            <Grid item xs={4}>
                                                <Chip
                                                    sx={{p: 3}}
                                                    color="primary"
                                                    avatar={<Avatar>CX</Avatar>}
                                                    label="Vrednost parametra CX"
                                                />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    size="small"
                                                    fullWidth
                                                    id="filled-basic"
                                                    label="Parametar CX"
                                                    variant="filled"
                                                    value={cx}
                                                    onChange={(e) => setCx(e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            handleCoefChange("cx", cx);
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Divider sx={{mt: 2}}>
                                        <Chip label="Y - grupa" size="small"/>
                                    </Divider>
                                    <Box sx={{flexGrow: 1, mt: 2}}>
                                        <Grid
                                            container
                                            spacing={2}
                                            sx={{
                                                textAlign: "center",
                                                justifyContent: "center",
                                                alignContent: "center",
                                            }}
                                        >
                                            <Grid item xs={4}>
                                                <Chip
                                                    sx={{p: 3}}
                                                    color="primary"
                                                    avatar={<Avatar>AY</Avatar>}
                                                    label="Vrednost parametra AY"
                                                />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    size="small"
                                                    fullWidth
                                                    id="filled-basic"
                                                    label="Parametar AY"
                                                    variant="filled"
                                                    value={ay}
                                                    onChange={(e) => setAy(e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            handleCoefChange("ay", ay);
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={{flexGrow: 1, mt: 2}}>
                                        <Grid
                                            container
                                            spacing={2}
                                            sx={{
                                                textAlign: "center",
                                                justifyContent: "center",
                                                alignContent: "center",
                                            }}
                                        >
                                            <Grid item xs={4}>
                                                <Chip
                                                    sx={{p: 3}}
                                                    color="primary"
                                                    avatar={<Avatar>BY</Avatar>}
                                                    label="Vrednost parametra BY"
                                                    value={by}
                                                />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    size="small"
                                                    fullWidth
                                                    id="filled-basic"
                                                    label="Parametar BY"
                                                    variant="filled"
                                                    value={by}
                                                    onChange={(e) => setBy(e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            handleCoefChange("by", by);
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={{flexGrow: 1, mt: 2}}>
                                        <Grid
                                            container
                                            spacing={2}
                                            sx={{
                                                textAlign: "center",
                                                justifyContent: "center",
                                                alignContent: "center",
                                            }}
                                        >
                                            <Grid item xs={4}>
                                                <Chip
                                                    sx={{p: 3}}
                                                    color="primary"
                                                    avatar={<Avatar>CY</Avatar>}
                                                    label="Vrednost parametra CY"
                                                />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    size="small"
                                                    fullWidth
                                                    id="filled-basic"
                                                    label="Parametar CY"
                                                    variant="filled"
                                                    value={cy}
                                                    onChange={(e) => setCy(e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            handleCoefChange("cy", cy);
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Divider sx={{mt: 2}}>
                                        <Chip label="Z - grupa" size="small"/>
                                    </Divider>
                                    <Box sx={{flexGrow: 1, mt: 2}}>
                                        <Grid
                                            container
                                            spacing={2}
                                            sx={{
                                                textAlign: "center",
                                                justifyContent: "center",
                                                alignContent: "center",
                                            }}
                                        >
                                            <Grid item xs={4}>
                                                <Chip
                                                    sx={{p: 3}}
                                                    color="primary"
                                                    avatar={<Avatar>AZ</Avatar>}
                                                    label="Vrednost parametra AZ"
                                                />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    size="small"
                                                    fullWidth
                                                    id="filled-basic"
                                                    label="Parametar AZ"
                                                    variant="filled"
                                                    value={az}
                                                    onChange={(e) => setAz(e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            handleCoefChange("az", az);
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={{flexGrow: 1, mt: 2}}>
                                        <Grid
                                            container
                                            spacing={2}
                                            sx={{
                                                textAlign: "center",
                                                justifyContent: "center",
                                                alignContent: "center",
                                            }}
                                        >
                                            <Grid item xs={4}>
                                                <Chip
                                                    sx={{p: 3}}
                                                    color="primary"
                                                    avatar={<Avatar>BZ</Avatar>}
                                                    label="Vrednost parametra BZ"
                                                />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    size="small"
                                                    fullWidth
                                                    id="filled-basic"
                                                    label="Parametar BZ"
                                                    variant="filled"
                                                    value={bz}
                                                    onChange={(e) => setBz(e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            handleCoefChange("bz", bz);
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={{flexGrow: 1, mt: 2}}>
                                        <Grid
                                            container
                                            spacing={2}
                                            sx={{
                                                textAlign: "center",
                                                justifyContent: "center",
                                                alignContent: "center",
                                            }}
                                        >
                                            <Grid item xs={4}>
                                                <Chip
                                                    sx={{p: 3}}
                                                    color="primary"
                                                    avatar={<Avatar>CZ</Avatar>}
                                                    label="Vrednost parametra CZ"
                                                />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    size="small"
                                                    fullWidth
                                                    id="filled-basic"
                                                    label="Parametar CZ"
                                                    variant="filled"
                                                    value={cz}
                                                    onChange={(e) => setCz(e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            handleCoefChange("cz", cz);
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            )}
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <Box sx={{flexGrow: 1, mt: 2}}>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{
                                        textAlign: "center",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}
                                >
                                    <Grid item xs={4}>
                                        <Chip
                                            sx={{p: 3}}
                                            color="primary"
                                            avatar={<Avatar>A</Avatar>}
                                            label="Trend A"
                                        />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            disabled
                                            size="small"
                                            fullWidth
                                            id="filled-basic"
                                            label="Vrednost trenda A"
                                            variant="filled"
                                            value={trendA}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={{flexGrow: 1, mt: 2}}>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{
                                        textAlign: "center",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}
                                >
                                    <Grid item xs={4}>
                                        <Chip
                                            sx={{p: 3}}
                                            color="primary"
                                            avatar={<Avatar>B</Avatar>}
                                            label="Trend B"
                                        />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            disabled
                                            size="small"
                                            fullWidth
                                            id="filled-basic"
                                            label="Vrednost trenda B"
                                            variant="filled"
                                            value={trendB}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={{flexGrow: 1, mt: 2}}>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{
                                        textAlign: "center",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}
                                >
                                    <Grid item xs={4}>
                                        <Chip
                                            sx={{p: 3}}
                                            color="primary"
                                            avatar={<Avatar>C</Avatar>}
                                            label="Trend C"
                                        />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            disabled
                                            size="small"
                                            fullWidth
                                            id="filled-basic"
                                            label="Vrednost trenda C"
                                            variant="filled"
                                            value={trendC}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </CustomTabPanel>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default SettingsPage;
